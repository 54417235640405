<template>
  <v-container fluid>
    <v-expansion-panel class="mb-3" inset>
      <v-expansion-panel-content class="CSP_BoarderStyle">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
          </div>
        </template>
        <v-card color="white" style="border:1px solid #000; border-radius:50px;">
          <v-layout row>
            <v-layout row wrap align-center>
              <v-flex class="mx-3">
                <v-layout row wrap align-center >
                  <v-flex >
                    <v-autocomplete
                      :disabled="ifCenterUser"
                      class="ma-2"
                      v-model="searchParam.center_code"
                      :item-text="
                        (item) => item.center_code + ' - ' + item.center_name
                      "
                      item-value="center_code"
                      :items="getTheoryCenterList"
                      menu-props="auto"
                      label="Select Center"
                      prepend-icon="list"
                      @change="getAbsentData()"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          close
                          @input="clearCenter()"
                          :selected="data.selected"
                          class="chip--select-multi"
                        >
                          {{ formatCenterText(data.item) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ formatCenterText(data.item) }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs6 sm4 style="padding-top: 24px">
                    <v-text-field
                      label="Room Number"
                      prepend-icon="list"
                      v-model="searchParam.room_number"
                      type="number"
                      single-line
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm4 style="padding-top: 24px">
                    <v-text-field
                      label="Pepar Code"
                      prepend-icon="list"
                      v-model="searchParam.paper_code"
                      type="number"
                      single-line
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm4 style="padding-top: 24px">
                    <v-text-field
                      label="Roll Number"
                      prepend-icon="list"
                      v-model="searchParam.exam_roll_no"
                      type="number"
                      single-line
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm4 style="padding-top: 24px">
                    <v-text-field
                      label="Regestration Number"
                      prepend-icon="list"
                      v-model="searchParam.reg_no"
                      type="number"
                      single-line
                    ></v-text-field>
                  </v-flex>

                  <!-- <v-menu full-width max-width="290" :close-on-content-click="true">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    color="cyan"
                    class="ma-2 title"
                    v-model="searchParam.date"
                    label="Select Attendance Date"
                    readonly
                    v-on="on"
                    outline
                    hide-details
                    prepend-icon="calendar_today"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date"></v-date-picker>
              </v-menu> -->
                </v-layout>
              </v-flex>
            </v-layout>
          </v-layout>
          <div class="text-xs-center" style="padding-bottom: 40px">
            <v-btn
              style="padding: 24px"
              round
              color="primary"
              @click="getAbsentData()"
              dark
            >
              <v-icon left dark>search</v-icon>Search
            </v-btn>
          </div>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-card color="white" style="margin-top: 40px">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title">Absent List</v-card-text>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <!-- <v-btn color="#3F51B5" @click="getSammaryofTodayExamPDF(1)">
          <v-icon size="20" color="#fff">edit</v-icon>
          <p style="margin-top: 16px; color: #fff"> Manual Absent Entry</p>
        </v-btn> -->
        <v-dialog v-model="absentEntryDialog" persistent width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on">Manual Absent Entry</v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="indigo">
              <v-toolbar-title>Manual Absent Entry</v-toolbar-title>
              <v-spacer />
              <v-btn
                fab
                class="ma-1 pa-0"
                small
                color="red darken-3 white--text"
                @click="(absentEntryDialog = false), clearobj()"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-layout row wrap align-center style="margin-top: -5%">
                <v-flex xs12 sm12 style="padding-top: 24px">
                  <v-text-field
                    label="Roll Number"
                    prepend-icon="list"
                    v-model="absentParam.exam_roll_no"
                    type="number"
                    single-line
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 style="margin-left: 84%; margin-top: -16%">
                  <v-btn
                    class="ma-1 pa-0"
                    color="indigo darken-3 white--text"
                    @click="searchExaminee()"
                  >
                    <v-icon>search</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout
                row
                wrap
                align-center
                style="margin-top: 10px"
                v-if="getExamineeDataForAbsent !== ''"
              >
                <v-flex xs12 sm12 style="padding-top: 14px; margin-left: 6%">
                  <!-- <span style="font-size: 14px; color: #797979"
                    >Name:
                    <span style="font-size: 16px; color: #538fec">{{
                      getExamineeDataForAbsent.name
                    }}</span></span
                  > -->
                  
                  <v-text-field
                    label="Examinee Name"
                    prepend-icon="list"
                    v-model="getExamineeDataForAbsent.name"
                    type="text"
                    single-line
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm4 style="padding-top: 24px; margin-left: 6%">
                  <!-- <span style="font-size: 16px; color: #797979"
                    >Reg No:
                    <span style="font-size: 16px; color: #538fec">
                      {{ getExamineeDataForAbsent.registration_number }}</span
                    ></span
                  > -->
                   <v-text-field
                    label="Reg No"
                    prepend-icon="list"
                    v-model="getExamineeDataForAbsent.registration_number"
                    type="text"
                    single-line
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm4 style="padding-top: 24px; margin-left: 16%">
                  <!-- <span style="font-size: 14px; color: #797979"
                    >Roll No:
                    <span style="font-size: 16px; color: #538fec">{{
                      getExamineeDataForAbsent.exam_roll_number
                    }}</span></span
                  > -->
                  <v-text-field
                    label="Roll No"
                    prepend-icon="list"
                    v-model="getExamineeDataForAbsent.exam_roll_number"
                    type="text"
                    single-line
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm4 style="padding-top: 24px; margin-left: 6%">
                  <!-- <span style="font-size: 14px; color: #797979"
                    >Pepar Code:
                    <span style="font-size: 16px; color: #538fec">{{
                      getExamineeDataForAbsent.paper_code
                    }}</span></span
                  > -->
                  <v-text-field
                    label="Pepar Code"
                    prepend-icon="list"
                    v-model="getExamineeDataForAbsent.paper_code"
                    type="number"
                    single-line
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 style="padding-top: 14px">
                  <v-text-field
                    label="Room Number"
                    prepend-icon="list"
                    v-model="absentParam.room_number"
                    type="number"
                    single-line
                    outline
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions style="margin-left: 40%">
              <v-btn :disabled="getExamineeDataForAbsent == ''" color="#3F51B5" @click="absentEntry()"
                ><span style="margin-top: 2px; color: #fff">
                  Absent</span
                ></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-btn color="#3F51B5" @click="getSammaryofTodayExamPDF(1)">
          <v-icon size="30" color="#fff">download</v-icon>
          <p style="margin-top: 16px; color: #fff">Download Absent Sammary</p>
        </v-btn> -->
        <v-btn color="#3F51B5" @click="getAbsentData()">
          <v-icon size="20" color="#fff">replay</v-icon>
          <p style="margin-top: 16px; color: #fff">Reload</p>
        </v-btn>
      </v-layout>
    </v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="getMenualAbsentDataList"
      class="elevation-1"
      hide-actions
    >
      <template v-slot:items="props">
        <td class="text-xs-center">{{ props.index + 1 }}</td>
        <td class="text-xs-left" style="padding-left: 10px">
          {{ props.item.student_name }}
        </td>
        <td class="text-xs-center">
          {{ props.item.exam_roll_no }}
        </td>
        <td class="text-xs-center">
          {{ props.item.reg_no }}
        </td>
        <td class="text-xs-center">
          <span>
            {{ props.item.paper_code }}
          </span>
        </td>
        <td class="text-xs-center">
          {{ props.item.room_number }}
        </td>
        <td class="text-xs-center">
          <v-btn
            small
            fab
            color="red"
            style="color: #fff"
            @click="deleteAbsentData(props.item.id)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import common_getters_mixin from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_exam_lg from "../../mixin/common_login_getters.vue";
export default {
  mixins: [common_getters_mixin, commonExamYear, common_exam_lg],
  data() {
    return {
      loading: false,
      searchParam: {},
      absentParam: {},
      absentEntryDialog: false,
      headers: [
        {
          text: "SL",
          align: "left",
          sortable: false,
          value: "",
        },
        { text: "Student Name", align: "center", value: "student_name" },
        { text: "Roll No", align: "center", value: "exam_roll_no" },
        { text: "Reg No", align: "center", value: "reg_no" },
        { text: "Paper Code", align: "center", value: "paper_code" },
        {
          text: "Room No",
          align: "center",
          value: "room_no",
        },
        { text: "Action", align: "center", value: "" },
      ],
    };
  },
  created() {
    if (this.ifCenterUser == true) {
      this.searchParam.center_code = this.getAuthUser.center.center_code;
    } else {
      this.searchParam.center_code = "";
    }
    this.searchParam.exam_initiate_id = this.getExamInitiateId;
    this.searchParam.exam_code = this.getLoggedInExam.code;
    this.searchParam.exam_year = this.getLoggedInYear.year;

    this.theworyyCenterData();
    this.getAbsentData();
  },
  computed: {
    getTheoryCenterList() {
      return this.$store.getters.getTheoryCenterList;
    },
    getMenualAbsentDataList() {
      return this.$store.getters.getAbsentList;
    },
    getExamineeDataForAbsent() {
      return this.$store.getters.getAbsentExaminee;
    },
  },
  watch: {},
  methods: {
    formatCenterText(item) {
      return item.center_code + " - " + item.center_name;
    },
    theworyyCenterData() {
      let param = {};
      param.exam_initiate_id = this.getExamInitiateId;
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      this.$store.dispatch("fetchTheoryWiseCenterData", param);
    },
    clearCenter() {
      console.log("Tanvir! called");
      this.searchParam.center_code = "";
      //   this.searchCenManualAbsEntryList();
    },
    getAbsentData() {
      console.log(
        "Tanvir! This is search of absent data",
        this.searchParam.center_code
      );
      if (this.searchParam.center_code) {
        this.$store.dispatch("fetchMenualAbsentList", this.searchParam);
      }
    },
    searchExaminee() {
      this.absentEntryDialog = true;
      console.log(
        "Tanvir! This is abdent search",
        this.absentParam.exam_roll_no
      );
      let pearm = {};
      //   pearm.exam_initiate_id = this.getExamInitiateId;
      pearm.exam_code = this.getLoggedInExam.code;
      pearm.exam_year = this.getLoggedInYear.year;
      pearm.student_identifier = this.absentParam.exam_roll_no;
      this.$store.dispatch("fetchExamineeDataByRoll", pearm).then((data)=>{
        console.log("Tanvir, This is absent ex search data", data);
        this.searchParam.name = data.name;
      });
    },
    absentEntry() {
      let absentParan = {};
      if (this.ifCenterUser == true) {
        absentParan.center_code = this.getAuthUser.center.center_code;
      } else {
        absentParan.center_code = this.searchParam.center_code;
      }
      absentParan.reg_no = this.getExamineeDataForAbsent.registration_number;
      absentParan.exam_roll_no = this.getExamineeDataForAbsent.exam_roll_number;
      absentParan.exam_year = this.searchParam.exam_year =
        this.getLoggedInYear.year;
      absentParan.exam_initiate_id = this.getExamInitiateId;
      absentParan.paper_code = this.getExamineeDataForAbsent.paper_code;
      absentParan.exam_code = this.getLoggedInExam.code;
      absentParan.room_number = this.absentParam.room_number;
      console.log("Tanvir!  This is absent param", absentParan);
      this.$store
        .dispatch("saveExamineeAbsentAttandance", absentParan)
        .then(() => {
          this.$store.dispatch("fetchMenualAbsentList", this.searchParam);
          //this.getExamineeDataForAbsent = '';
          // this.getExamineeDataForAbsent.name = '';
          // this.getExamineeDataForAbsent.registration_number = '';
          // this.getExamineeDataForAbsent.exam_roll_number = '';
          // this.getExamineeDataForAbsent.paper_code = '';
          this.absentParam.exam_roll_no = '';
        });
    },
    clearobj() {
      this.absentParam.exam_roll_no = "";
    },
    deleteAbsentData(id) {
      this.$store.dispatch("fetchDeleteAbsentData", id).then(() => {
        if (this.searchParam.center_code) {
          this.$store.dispatch("fetchMenualAbsentList", this.searchParam);
        }
        console.log("Jhunjhuni Deleted");
      });
    },
  },
};
</script>

<style></style>
